define("discourse/plugins/waze-login-flow-override/discourse/initializers/waze-login-flow-override-initializer", ["exports", "discourse/lib/plugin-api", "discourse/routes/application", "discourse-common/lib/get-url"], function (_exports, e, o, r) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var i = {
      d: (e, o) => {
        for (var r in o) i.o(o, r) && !i.o(e, r) && Object.defineProperty(e, r, {
          enumerable: !0,
          get: o[r]
        });
      },
      o: (e, o) => Object.prototype.hasOwnProperty.call(e, o)
    },
    t = {};
  i.d(t, {
    A: () => p
  });
  const n = (a = {
    withPluginApi: () => e.withPluginApi
  }, l = {}, i.d(l, a), l);
  var a, l;
  const s = (e => {
      var o = {};
      return i.d(o, e), o;
    })({
      default: () => o.default
    }),
    u = (e => {
      var o = {};
      return i.d(o, e), o;
    })({
      default: () => r.default
    });
  function d() {
    window.location = (0, u.default)(`/session/sso?return_path=${encodeURIComponent(window.location.href)}`);
  }
  const p = {
    name: "wz-login-flow-override",
    initialize(e) {
      (0, n.withPluginApi)("1.6.0", function () {
        for (var _len = arguments.length, o = new Array(_len), _key = 0; _key < _len; _key++) {
          o[_key] = arguments[_key];
        }
        const r = e.lookup("service:site-settings"),
          i = e.lookup("service:app-events");
        s.default.reopen({
          handleShowLogin() {
            if (!r.waze_login_flow_override_enabled) return this._super(...o);
            d();
          }
        }), i.on("page:changed", () => {
          r.waze_login_flow_override_enabled && Array.from(document.querySelectorAll('a[href*="/login"]')).forEach(e => {
            e.onclick = e => {
              e.stopPropagation(), e.preventDefault(), d();
            };
          });
        });
      });
    }
  };
  var c = _exports.default = t.A;
});